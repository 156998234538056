<template>
  <div class="bg-frame">
    <Challenge :challenge="challenge" />
  </div>
</template>

<script>
import Challenge from '@/components/Challenge'
import { getChallenge } from '@/api/challenge'

export default {
  name: 'ChallengeView',

  components: {
    Challenge
  },

  data () {
    return {
      challenge: {
        id: '',
        challenger: {
          id: '',
          username: '',
          first_name: '',
          last_name: '',
          avatar: null
        },
        challengee: {
          first_name: '',
          last_name: '',
          avatar: null
        },
        hashtags: [],
        likes: 0,
        comments: 0,
        positive_votes: 0,
        negative_votes: 0,
        created: '',
        modified: '',
        challenge_status: 0,
        statement: '',
        failure_statement: '',
        expiry_date: '',
        invite_link: null,
        challenger_video: null,
        challengee_video: null
      }
    }
  },

  created () {
    this.getChallenge(this.$route.params.id)
  },

  methods: {
    async getChallenge (challengeId) {
      const res = await getChallenge(challengeId)
      this.challenge = res.data
    }
  }

}
</script>

<style scoped>
.bg-frame{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
