import axios from 'axios'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json'
  }
})

function errorResponseHandler (error) {
  if (error.response) {
    console.error(error.response.data.message)
    Promise.reject(error)
  }
}

// apply interceptor on response
axios.interceptors.response.use(
  response => response,
  errorResponseHandler
)

export default httpClient
