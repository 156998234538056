<template>
  <b-container>
    <b-card class="challenge-wrapper mx-auto">
      <b-row class="first-row">
        <b-col
          cols="5"
          class="d-flex justify-content-start align-items-center"
        >
          <b-avatar
            :src="challenge.challenger.avatar"
          />
          <div class="ml-2">
            <span class="challenge__username">
              @{{ challenge.challenger.username }}
            </span>
          </div>
        </b-col>
        <b-col cols="2">
          <div />
        </b-col>
        <b-col
          cols="5"
          class="d-flex justify-content-end align-items-center"
        >
          <template v-if="challenge.challengee">
            <div class="mr-2">
              <span class="challenge__username">
                @{{ challenge.challengee.username || '' }}
              </span>
            </div>
            <b-avatar
              :src="challenge.challengee.avatar || null"
            />
          </template>
          <template v-else>
            <div class="mr-2">
              <span style="font-size: 10pt">
                No Challengee
              </span>
            </div>
            <b-avatar />
          </template>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="challenge__failure">
            {{ challenge.failure_statement }}
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mt-4"
        style="padding: 0 12px;"
      >
        <b-col style="padding: 0">
          <div
            class="challenger-video video-wrapper"
            @click="startChallengeVideo(true)"
          >
            <video
              class="video-preview"
              controls
              :src="challenge.challenger_video"
            />
          </div>
        </b-col>
        <b-col style="padding: 0">
          <div
            class="challengee-video video-wrapper"
            @click="startChallengeVideo(false)"
          >
            <template v-if="challenge.challengee_video">
              <video
                controls
                class="video-preview"
                :src="challenge.challengee_video"
              />
            </template>
            <template v-else>
              <div class="video-bg" />
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row class="center mt-1">
        <a
          :href="`${challenge.invite_link}`"
          target="_blank"
        >
          <b-icon-hand-thumbs-up />
        </a>

        <span style="color:black; margin: 12px 0px;">{{ challenge.statement }}</span>
        <a
          :href="`${challenge.invite_link}`"
          target="_blank"
        >
          <b-icon-hand-thumbs-down />
        </a>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import '@/scss/challenge.scss'

export default {

  name: 'Challenge',

  props: {
    challenge: { type: Object, default: null }
  },

  data () {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: '75 auto',
        height: '75 auto',
        class: 'm1',
        center: true
      },
      hoveredChallengeeVideo: false
    }
  },
  metaInfo () {
    return {
      title: 'iToldya',
      meta: [
        {
          name: 'description',
          content: 'iToldYa - create a challenge!'
        },
        {
          property: 'og:title', content: 'iToldYa'
        },
        {
          property: 'og:type', content: 'website'
        },
        {
          property: 'og:description', content: this.challenge.statement
        },
        {
          property: 'og:url', content: 'https://dev.itoldya.net/challenge/' + this.challenge.id
        },
        {
          property: 'og:image', content: this.challenge.challenger_thumbnail
        },
        {
          property: 'twitter:card', content: 'summary_large_image'
        },
        {
          property: 'twitter_url', content: 'https://dev.itoldya.net/challenge/' + this.challenge.id
        },
        {
          property: 'twitter:title', content: 'iToldYa'
        },
        {
          property: 'twitter:image', content: this.challenge.challenger_thumbnail
        }
      ]
    }
  },
  methods: {
    startChallengeVideo (challenger) {
      challenger ? this.hoveredChallengeeVideo = false : this.hoveredChallengeeVideo = true
    }
  }
}
</script>

<style>

.video-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}

.video-wrapper {
  width: 100%;
  height: 450px;
  min-height: 350px;
  overflow: hidden;
}

</style>
