import Vue from 'vue'
import VueRouter from 'vue-router'
import ChallengeView from '@/views/ChallengeView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomeView')
  },
  {
    path: '/challenges',
    name: 'ListOfChallenges',
    component: () => import('@/views/ListView')
  },
  {
    path: '/challenge/:id',
    name: 'ChallengeView',
    component: ChallengeView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
